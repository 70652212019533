$ = require('jquery');
window.$ = $;

require('jqwidgets/jqxcore.js');
require('jqwidgets/jqxdata.js');
require('jqwidgets/jqxbuttons.js');
require('jqwidgets/jqxdragdrop.js');
require('jqwidgets/jqxscrollbar.js');
require('jqwidgets/jqxdatatable.js');
require('jqwidgets/jqxdropdownlist.js');
require('jqwidgets/jqxlistbox.js');
require('jqwidgets/jqxcheckbox.js');
require('jqwidgets/jqxpanel.js');
require('jqwidgets/jqxtree.js');
require('jqwidgets/jqxmenu.js');

require('jqwidgets/jqxgrid.js');
require('jqwidgets/jqxgrid.pager.js');
require('jqwidgets/jqxgrid.filter.js');
require('jqwidgets/jqxgrid.columnsresize.js');
require('jqwidgets/jqxgrid.sort.js');
require('jqwidgets/jqxgrid.selection.js');

/* 
  // require('jqwidgets/styles/jqx.base.css');
  // require('jqwidgets/styles/jqx.bootstrap.css');

  These stylesheets are required for jqWidgets to work. It is imported in
  frontend/apps/default/index.js to not override bootstrap stylesheet and be overriten by overrides.css.
*/
